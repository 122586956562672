import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import { LoadDataErrors, navigationGroupType, PathNames } from '../../../types/types';
import s from './RouterPage.module.css';
import NotificationMessages from '../../modules/NotificationMessages/NotificationMessages';
import { SideNav } from '../../modules/SideNav/SideNav';
import { errorType, InstancePublicDataType, notificationEnum } from '../../../types/appTypes';
import { utils_instance } from '../../../utils/Utils';
import WebSocketItem from '../../../api/WebSocket';
import {
  addNotificationAC,
  resetAppStateAC,
  setAllInstancesListAC,
  setCurrentInstanceDataAC,
  setCurrentIpAC,
  setCurrentPathAC,
  setErrorStatusAC,
} from '../../../store/appReducer';
import axios from 'axios';
import { weworkAPI } from '../../../api/api';
import { setUserInfoAC } from '../../../store/authReducer';
import cs from '../../../styles/commonStyles.module.css';
import { CircularProgress } from '@mui/material';
import { ReadersPage } from '../ReadersPage';
import { ControllersPage } from '../ControllersPage';
import { DownstreamPage } from '../DownstreamPage';
import { CamerasPage } from '../CamerasPage';
import { IntercomsPage } from '../IntercomsPage';
import { HardwareUsersPage } from '../HardwareUsersPage';
import { UserLogsPage } from '../UserLogsPage';
import { AnalyticsPage } from '../AnalyticsPage';
import { InstancesListPage } from '../InstancesListPage';
import { ElevatorsPage } from '../ElevatorsPage';
import { HomePage } from '../HomePage';
import { ErrorPage } from '../ErrorPage';
import { SysLogsPage } from '../SysLogsPage';
import { useCurrentIp } from '../../../store/selectors/useCurrentIp';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useUserInfo } from '../../../store/selectors/useUserInfo';
import { InstanceGroupPage } from '../InstanceGroupPage';
import { InstanceGroupDetailResponseType } from '../../../types/instancesGroupsTypes';
import { CountingAreasPage } from '../CoutingAreasPage.tsx';
import { setInstanceGroupDataAC } from '../../../store/instanceGroupsReducer';
import { InstanceGroupActionPage } from '../InstanceGroupActionPage';
import { HolidayPage } from '../HolidayPage';
import { useIsIPv6 } from '../../../store/selectors/useIsIPv6';

const RouterPageWrapper = () => {
  return (
    <div className={s.routerPageBody}>
      <RouterPage />
      <NotificationMessages />
    </div>
  );
};

const RouterPage = () => {
  const dispatch = useDispatch();

  const error = useSelector<AppRootStateType, errorType>((state) => state.app.error);

  const currentIp = useCurrentIp();
  const isIPv6 = useIsIPv6();
  const currentInstanceData = useCurrentInstanceData();
  const userInfo = useUserInfo();

  const instancesList = useSelector<AppRootStateType, InstancePublicDataType[]>(
    (state) => state.app.instancesList,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [countReset, setCountReset] = useState<number>(0);

  const setErrorNotification = (currentIp: string) => {
    if (userInfo?.user_permissions.is_security_permission) {
      dispatch(setCurrentIpAC(currentIp));
      return;
    }

    const message = 'Thanks, so it seems that your network adapter is set to default to IPv6.' +
      ' Could you please open System Preferences, type IPv6 in search box,' +
      ' in Configure IPv6, please select Link-Local Only option. After that, please' +
      ' turn off WiFi and turn it back on and check if things are working';

    // to trigger useEffect for call getCurrentIP after resetAppStateAC
    dispatch(setCurrentIpAC('failed'));
    dispatch(addNotificationAC(
      notificationEnum.error, 'Ensure you are using an IPv4 connection'));
    dispatch(setErrorStatusAC({
      messageError: message,
      typeError: LoadDataErrors.loadDataError,
    }));
  };

  const getUserInfo = async () => {
    //1st step - get all info about user
    setIsLoading(true);

    try {
      const userInfo = await weworkAPI.getUserInfo();
      dispatch(setUserInfoAC(userInfo));
    } catch (e: any) {
      let errorMsg: string;

      if (e.response?.data?.detail) {
        errorMsg = `Load user role failed - ${e.response?.data?.detail}`;
        dispatch(
          setErrorStatusAC({
            messageError: errorMsg,
            typeError: LoadDataErrors.loginError,
          }),
        );
      } else {
        errorMsg = 'Load user role failed - unknown error';
        dispatch(
          setErrorStatusAC({
            messageError: errorMsg,
            typeError: LoadDataErrors.unknownError,
          }),
        );
      }

      setIsLoading(false);
      dispatch(addNotificationAC(notificationEnum.error, errorMsg));
    }
  };

  const getCurrentIP = async () => {
    //2nd step - get current ip address for user
    let isError = false;

    axios
      .get('https://ipapi.co/json/')
      .then((r: any) => {
        if (r.data.version === 'IPv6') {
          dispatch(setCurrentIpAC(r.data.ip, true));
        } else {
          dispatch(setCurrentIpAC(r.data.ip));
        }
      })
      .catch((e: any) => {
        !isError && axios
          .get('https://api.my-ip.io/v2/ip.json')
          .then((r: any) => {
            if (r.data.type === 'IPv6') {
              dispatch(setCurrentIpAC(r.data.ip, true));
            } else {
              dispatch(setCurrentIpAC(r.data.ip));
            }
          })
          .catch((e: any) => {
            const errorMsg = 'Load IP address failed';

            dispatch(addNotificationAC(notificationEnum.error, errorMsg));
            dispatch(
              setErrorStatusAC({
                messageError: errorMsg,
                typeError: LoadDataErrors.loadDataError,
              }),
            );
          });
      });
  };

  const getCurrentInstance = async () => {
    //3rd-B step - need for receive current instance by ip
    try {
      const currentInstance = await weworkAPI.getCurrentInstance(currentIp);
      dispatch(setCurrentInstanceDataAC(currentInstance.instances[0]));
      // we use first instance by default because in most of the cases we will have only one instance.
      dispatch(setAllInstancesListAC(currentInstance.instances));
    } catch (e: any) {
      if(isIPv6) return setErrorNotification(currentIp);

      const errorMsg = `Failed to load current instance - ${e.response?.data?.detail}`;
      dispatch(
        setErrorStatusAC({
          messageError: errorMsg,
          typeError: LoadDataErrors.loadDataError,
        }),
      );
      dispatch(addNotificationAC(notificationEnum.error, errorMsg));
    }
  };

  const getInstances = async () => {
    //3rd-A step - need for get all instances list
    try {
      const instancesResponse = await weworkAPI.getAllInstancesList();
      dispatch(setAllInstancesListAC(instancesResponse.instances));
    } catch (e: any) {
      const errorMsg = `Load instances list failed - ${e.response?.data?.detail}`;
      dispatch(
        setErrorStatusAC({
          messageError: errorMsg,
          typeError: LoadDataErrors.loadDataError,
        }),
      );
      dispatch(addNotificationAC(notificationEnum.error, errorMsg));
    }
  };

  const getInstancesByUserRole = async () => {
    //3rd step - need for switch way to get instance
    if (!userInfo) {
      return null;
    }
    if (
      userInfo.user_permissions.instances_dc_receive_permission ||
      userInfo.user_permissions.instances_no_dc_receive_permission
    ) {
      await getInstances();
    } else {
      if (userInfo.user_permissions.instance_receive_permission) {
        await getCurrentInstance();
      }
    }
    setIsLoading(false);
  };

  const getInstanceFromCache = () => {
    //4th-A step - parse instance from cache
    const savedInstance = localStorage.savedInstance;
    if (savedInstance) {
      const parsedInstance = JSON.parse(savedInstance);
      const validInstance = utils_instance.validateParams(
        parsedInstance.platform,
        parsedInstance.server_id,
        parsedInstance.instance_id,
        instancesList,
      );
      if (validInstance) {
        dispatch(setCurrentInstanceDataAC(validInstance));
        dispatch(setCurrentPathAC(PathNames.readers));
      } else {
        localStorage.removeItem('savedInstance');
      }
    }
  };

  const getInstanceFromPath = (savedPath: string) => {
    //4th-B step - parse instance from url path
    const url = savedPath.split('/');
    const validInstance = utils_instance.validateParams(
      url[3],
      Number(url[5]),
      url[7],
      instancesList,
    );
    if (validInstance) {
      dispatch(setCurrentInstanceDataAC(validInstance));

      if (url[1] === PathNames.instance_groups_action) {
        const id = url[9];
        id && weworkAPI.getInstanceGroupById(Number(id))
          .then(data => dispatch(setInstanceGroupDataAC(data)));
        dispatch(setCurrentPathAC(PathNames.instance_groups_action));
      } else {
        dispatch(
          setCurrentPathAC(
            url[1] in PathNames ? PathNames[url[1] as keyof typeof PathNames] : PathNames.readers,
          ),
        );
      }
    }
    sessionStorage.removeItem('path');
  };

  const checkSavedInstance = () => {
    //4th step - check if user has saved instance as default or parse instance from path
    const savedPath = sessionStorage.path;
    if (!savedPath) {
      setIsLoading(false);
      return;
    }
    if (savedPath !== '/') {
      getInstanceFromPath(savedPath);
    } else {
      sessionStorage.removeItem('path');
      getInstanceFromCache();
    }
    setIsLoading(false);
  };

  const reloadComponent = () => {
    //5th step - if got error during load main data
    if (countReset < 2) {
      dispatch(resetAppStateAC());
      setCountReset(countReset + 1);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //0 step
    const path = sessionStorage.getItem('path');
    if (!path) {
      sessionStorage.setItem('path', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (!currentInstanceData || !userInfo) {
      return;
    }
    weworkAPI.setVisit(
      currentIp,
      currentInstanceData.instance_id,
      currentInstanceData.server_id,
      currentInstanceData.platform,
    );
  }, [currentInstanceData, userInfo]);

  useEffect(() => {
    //1st step - get userinfo
    !userInfo && getUserInfo();
  }, [userInfo]);

  useEffect(() => {
    //2nd step - get current ip address for user
    !!userInfo && !currentIp.length && getCurrentIP();
  }, [currentIp, userInfo]);

  useEffect(() => {
    //3rd step - get instances by user role
    !!currentIp.length && currentIp !== 'failed' && !!userInfo && getInstancesByUserRole();
  }, [currentIp, userInfo]);

  useEffect(() => {
    //4th step - check if user has saved instance as default
    if (!isLoading && !!instancesList.length) {
      !!userInfo && checkSavedInstance();
    }
  }, [isLoading, instancesList]);

  useEffect(() => {
    //5th step - reload workflow get main data
    error.typeError !== LoadDataErrors.notError && reloadComponent();
  }, [error]);

  if (isLoading) {
    return (
      <div className={cs.position__center}>
        <CircularProgress />
      </div>
    );
  } else {
    return <SelectRouter />;
  }
};

const SelectRouter: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const error = useSelector<AppRootStateType, errorType>((state) => state.app.error);

  const currentPath = useSelector<AppRootStateType, PathNames>((state) => state.app.currentPath);

  const currentInstanceData = useCurrentInstanceData();

  const currentInstanceGroup
    = useSelector<AppRootStateType, InstanceGroupDetailResponseType | null>(
      (state) => state.instanceGroups.instanceGroupData);
  const userInfo = useUserInfo();

  const [redirectPath, setRedirectPath] = useState<string>(PathNames.readers);
  const [url, setUrl] = useState<string>('');
  const [menu, setMenu] = useState<navigationGroupType[]>([]);

  const changeInitRedirectPath = () => {
    if (error.messageError) {
      navigate(`${PathNames.error}`);
      dispatch(setCurrentPathAC(PathNames.error));
      return;
    }

    if (!userInfo) {
      return null;
    }

    const instances_receive_permissions =
      userInfo.user_permissions.instances_dc_receive_permission ||
      userInfo.user_permissions.instances_no_dc_receive_permission;

    const newCurrentPath = currentPath; //readers

    let newUrl = '';

    if (currentInstanceData) {
      const { platform, server_id, instance_id } = currentInstanceData;
      newUrl = `platform/${platform}/server/${server_id}/instance/${instance_id}`;
    }

    if (userInfo.user_permissions.obliterate_user_permission &&
      !userInfo.user_permissions.is_security_permission) {
      if (currentInstanceData) {
        navigate(`${newCurrentPath}/${newUrl}`);
        dispatch(setCurrentPathAC(newCurrentPath));
        setRedirectPath(`${newCurrentPath}/${newUrl}`);
        setUrl(newUrl);
        return;
      }
      navigate(`${PathNames.hardware_users}/${newUrl}`);
      dispatch(setCurrentPathAC(PathNames.hardware_users));
      setRedirectPath(`${newCurrentPath}/${newUrl}`);
      setUrl(newUrl);
      return;
    }
    if (instances_receive_permissions) {
      if (currentInstanceGroup && currentPath === PathNames.instance_groups_action) {
        setRedirectPath(`${PathNames.instance_groups_action}/${newUrl}`);
        navigate(
          `${PathNames.instance_groups_action}/${newUrl}/instance/${currentInstanceGroup.id}`
        );
        setUrl(newUrl);
        return;
      }
      if (currentInstanceData) {
        navigate(`${newCurrentPath}/${newUrl}`);
        dispatch(setCurrentPathAC(newCurrentPath));
        setRedirectPath(`${newCurrentPath}/${newUrl}`);
        setUrl(newUrl);
        return;
      }
      setRedirectPath('/');
      setUrl('');
      return;
    }

    if (userInfo.user_permissions.instance_receive_permission) {
      if (currentInstanceData) {
        navigate(`${newCurrentPath}/${newUrl}`);
        dispatch(setCurrentPathAC(newCurrentPath));
        setRedirectPath(`${newCurrentPath}/${newUrl}`);
        setUrl(newUrl);
        return;
      }
      if (error.messageError) {
        navigate(`${PathNames.error}`);
        dispatch(setCurrentPathAC(PathNames.error));
        return;
      }
      return;
    }

    if (error.messageError) {
      setRedirectPath(`${newCurrentPath}/${newUrl}`);
      setUrl(newUrl);
      return;
    }
    navigate(`${PathNames.instances_list}`);
    dispatch(setCurrentPathAC(PathNames.instances_list));
    return;
  };

  useEffect(() => {
    changeInitRedirectPath();
  }, [currentInstanceGroup, currentInstanceData, currentPath, error]);

  useEffect(() => {
    if (userInfo) {
      const draftMenu = utils_instance.fillMainMenu(userInfo);
      setMenu(draftMenu);
    }
  }, []);

  return (
    <div className={s.mainPageBody}>
      <SideNav header="WeWork" groups={menu} url={url} />
      <Suspense
        fallback={<div className={cs.position__center}>
          <CircularProgress />
        </div>}
      >
        <Routes>
          {userInfo?.user_permissions?.reader_receive_permission && (
            <Route path={`${PathNames.readers}/${url}`} element={<ReadersPage />} />
          )}
          {userInfo?.user_permissions?.controller_receive_permission && (
            <Route path={`${PathNames.controllers}/${url}`} element={<ControllersPage />} />
          )}
          {userInfo?.user_permissions?.counting_areas_receive_permission && (
            <Route path={`${PathNames.counting_areas}/${url}`} element={<CountingAreasPage />} />
          )}
          {userInfo?.user_permissions?.downstream_receive_permission && (
            <Route path={`${PathNames.downstreams}/${url}`} element={<DownstreamPage />} />
          )}
          {userInfo?.user_permissions?.camera_info_permission && (
            <Route path={`${PathNames.cameras}/${url}`} element={<CamerasPage />} />
          )}
          {userInfo?.user_permissions?.intercom_info_permission && (
            <Route path={`${PathNames.intercoms}/${url}`} element={<IntercomsPage />} />
          )}
          {userInfo?.user_permissions?.obliterate_user_permission && (
            <Route path={`${PathNames.hardware_users}/${url}`} element={<HardwareUsersPage />} />
          )}
          {userInfo?.user_permissions?.user_logs_permission && (
            <Route path={`${PathNames.user_log}/${url}`} element={<UserLogsPage />} />
          )}
          {userInfo?.user_permissions?.analytics_permission && (
            <Route path={`${PathNames.analytic}/${url}`} element={<AnalyticsPage />} />
          )}
          {userInfo?.user_permissions?.instances_extended_public_data_receive_permission && (
            <Route path={`${PathNames.instances_list}/${url}`} element={<InstancesListPage />} />
          )}
          {userInfo?.user_permissions?.elevator_receive_permission && (
            <Route path={`${PathNames.elevators}/${url}`} element={<ElevatorsPage />} />
          )}
          {userInfo?.user_permissions?.user_logs_permission && (
            <Route path={`${PathNames.sys_log}/${url}`} element={<SysLogsPage />} />
          )}
          {userInfo?.user_permissions?.instance_group_permission && (
            <Route path={`${PathNames.instance_groups}/${url}`} element={<InstanceGroupPage />} />
          )}
          {userInfo?.user_permissions?.instance_group_permission && (
            <Route
              path={
                `${PathNames.instance_groups_action}/${url}/instance/${currentInstanceGroup?.id}`
              }
              element={<InstanceGroupActionPage />} />
          )}
          {userInfo?.user_permissions?.holiday_permission && (
            <Route path={`${PathNames.holiday}/${url}`} element={<HolidayPage />} />
          )}
          <Route path={`${PathNames.error}`} element={<ErrorPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to={redirectPath} replace />} />
        </Routes>
      </Suspense>
      {currentInstanceData && <WebSocketItem />}
    </div>
  );
};

export default RouterPageWrapper;
